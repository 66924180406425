.style_container__1vhSh {
  display: flex;
  align-items: center;
}
.style_separator__2na_M {
  margin: 0 0.125rem;
}
.style_ampm__1eoZ3 {
  margin-left: 0.1875rem;
}
