@media (max-width: 37.4rem) {
  .NoResults_hide-sm__25gz6 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .NoResults_visible-sm__2iZ2v {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .NoResults_hide-md__1XFih {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .NoResults_visible-md__3uQAR {
    display: none !important;
  }
}
.NoResults_container__3pxpu {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.NoResults_onboardingImage__2n-Eu {
  margin: 1.5rem 0;
  height: 7rem;
}
