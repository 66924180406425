.projectSwitcher_container__1MyF5 {
  display: flex;
  flex-direction: column;
  width: 25rem;
}
.projectSwitcher_menuContainer__2MrWv {
  overflow-y: auto;
  height: 25rem;
}
.projectSwitcher_filterFieldContainer__2t3nq {
  width: 100%;
}
.projectSwitcher_groupName__2-CWo {
  background-color: rgba(153, 153, 153, 0.08);
  padding: 0.5rem 1.125rem;
  font-size: 0.9375rem;
}
.projectSwitcher_empty__2zGYF {
  padding: 0.625rem;
}
