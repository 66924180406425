.style_actionsContainer__3qEJy {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex: 0 1 auto;
}
.style_alignRightContainer__335o_ {
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem !important;
}
.style_spacer__3SsUA {
  flex: 1 0 auto;
}
