.CustomDialogContent_content__3dFpq {
  flex: auto;
  overflow-y: auto;
}
.CustomDialogContent_block__2f-Gc {
  height: 100%;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.CustomDialogContent_flex__3C05I {
  display: flex;
  flex-direction: column;
}
.CustomDialogContent_flex__3C05I > * {
  flex: auto;
}
.CustomDialogContent_padded__89-Js {
  padding-left: 1rem;
  padding-right: 1rem;
}
