.MultiSelect_textContainer__cCngl {
  position: relative;
}
.MultiSelect_iconContainer__3KKbb {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 0.5625rem;
}
.MultiSelect_error__3z4k6 {
  font-Size: 0.75rem;
  line-Height: 0.75rem;
  color: #db4437;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.MultiSelect_label__2EnYv {
  line-height: 22px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  user-select: none;
  color: #2f93e0;
  font-size: 0.75rem;
  font-weight: 400;
}
