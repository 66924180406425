.style_addRowsContainer__wf7cH {
  width: 100%;
  height: 100%;
}
.style_addRowBackground__T54sB {
  background-color: rgba(153, 153, 153, 0.08);
}
.style_controlCell__2_cm6 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.style_scopeCell__Ob_tx {
  height: 100%;
}
.style_actions__Yq49Y {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  border-bottom: 2px solid #cccccc;
  background-color: #ffffff;
}
.style_scopeWrapper__2FH6S {
  flex: 1 1 auto;
}
