.style_icon__yh6Eh {
  width: 2rem;
  height: 2rem;
  padding-right: 0.5rem;
}
.style_link__2xrND {
  height: 100%;
  display: flex;
  align-items: center;
}
.style_tooltipWrapper__2-gt7 {
  height: 100%;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.style_text__1MqDu {
  min-width: 0;
  flex-basis: 0;
}
