.CustomDialogActions_actions__VopWq {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap;
}
.CustomDialogActions_wizardActions__ntU0r {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap;
}
.CustomDialogActions_primaryActions__1Zb3E > * {
  margin-left: 0.5rem;
}
.CustomDialogActions_secondaryActions__3vWSJ > * {
  margin-right: 0.5rem;
}
.CustomDialogActions_step__3AtUu {
  height: 0.25rem;
  flex: 1 1 auto;
  background-color: #cae4f7;
  margin-right: 0.25rem;
  cursor: pointer;
}
.CustomDialogActions_step__3AtUu.CustomDialogActions_active__3KYHe {
  background-color: #2f93e0;
}
.CustomDialogActions_steps__7SYQR {
  display: flex;
  justify-content: center;
  width: 50%;
  align-self: center;
}
.CustomDialogActions_steps__7SYQR :first-child {
  margin-left: 0.25rem;
}
