@media (max-width: 37.4rem) {
  .style_hide-sm__FmwJW {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1Y9fX {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__HZKrm {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1GGVf {
    display: none !important;
  }
}
.style_logo__1vlwa {
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_missingIconContainer__10r6Z {
  position: absolute;
}
.style_missingIconInner__2xnxV {
  position: relative;
  top: -1.5rem;
  right: -1.5rem;
}
