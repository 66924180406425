.styles_clickableChip__12tM1 > div:first-child:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  border-radius: 1rem;
  z-index: 0;
}
.styles_clickableChip__12tM1 > div:first-child:hover:after {
  opacity: 1;
}
.styles_clickableChip__12tM1 > div span {
  z-index: 1;
}
