.style_filterBox__2acBd {
  margin-left: 1rem;
  margin-top: 2.6rem;
}
.style_filter__1EUBM {
  width: 50rem;
  align-items: flex-end;
}
.style_row__3ePFI {
  display: flex;
  flex-direction: row;
}
.style_logo__1e-_K {
  vertical-align: middle;
  justify-content: center;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
}
.style_linkWithCopy__e0UAO {
  display: inline-flex;
  flex-direction: row;
}
.style_linkWithCopy__e0UAO .style_copyAction__dwHR5 {
  margin: -1rem 0;
}
.style_spaceName__1Om_2 {
  font-weight: 500;
}
.style_addTeamInstructions__8UbAB {
  margin-bottom: 0.1875rem;
}
.style_addTeamInstructionsList__33fXy {
  list-style-type: decimal;
  margin: 0;
  padding-left: 1.5625rem;
}
.style_spaceLimitHit__2H4XW {
  text-align: center;
}
.style_spaceManagersCalloutContainer__29yZ1 {
  margin-bottom: 0.5rem;
}
