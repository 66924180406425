@media (max-width: 37.4rem) {
  .style_hide-sm__zDSF- {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__nHlFU {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__DGFK5 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__VoqbV {
    display: none !important;
  }
}
.style_deploymentResultItem__3s8kB .style_deploymentsTableRow__3fv-m {
  letter-spacing: 0px;
  cursor: pointer;
}
.style_deploymentResultItem__3s8kB .style_deploymentsTableRow__3fv-m a {
  font-weight: 400;
}
.style_deploymentResultItem__3s8kB .style_deploymentsTableRow__3fv-m td {
  vertical-align: middle !important;
}
.style_deploymentResultItem__3s8kB .style_deploymentsTableRow__3fv-m td:last-child {
  padding-bottom: 0.5rem;
}
.style_deploymentResultItem__3s8kB .style_section__g-q84 {
  font-weight: 400;
}
.style_deploymentResultItem__3s8kB .style_healthStatusIcon__2FUtt {
  padding: 0 0.25rem 0 0;
}
.style_deploymentResultItem__3s8kB .style_summaryCountContainer__1DDZg {
  display: flex;
}
.style_deploymentResultItem__3s8kB .style_summaryCount__3KoNC {
  margin: 0.25rem 0;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;
  line-height: 1rem !important;
}
.style_deploymentResultItem__3s8kB .style_environmentOrTenantIcon__1tOFm {
  display: inline-block;
}
.style_deploymentResultItem__3s8kB .style_targetColumn__3URTF {
  display: flex;
  align-items: center;
}
.style_deploymentResultItem__3s8kB .style_iconContainer__PDxGx {
  display: inline-block;
}
.style_deploymentResultItem__3s8kB .style_icon__1ykjW {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
  font-size: 1rem;
  color: #ffffff;
}
.style_deploymentResultItem__3s8kB .style_success__7ID3R {
  color: #48B350;
}
.style_deploymentResultItem__3s8kB .style_error__Idl4H {
  background-color: #db4437;
  color: #ffffff;
}
.style_expandCollapse__mRCVJ {
  display: flex;
  justify-content: flex-end;
}
