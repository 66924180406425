.ProjectedTeamsList_root__35rt4 {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.ProjectedTeamsList_detail__3qw0m {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 1rem;
}
.ProjectedTeamsList_icon__3BCOo {
  width: 32px;
  height: 32px;
  fill: #333333 !important;
}
.ProjectedTeamsList_externalGroups__2pD1f {
  color: #333333;
}
