.style_scrollUpButton__3S5Av {
  width: 30px;
  height: 30px;
  background-color: rgba(102, 102, 102, 0.5) !important;
  padding: 1.5rem;
  z-index: 100;
  border-radius: 30px;
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.style_ScrollUpButton__Container__oH0hx {
  position: fixed;
  right: -100px;
  bottom: 20px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  width: 30px;
  height: 30px;
  background-color: rgba(102, 102, 102, 0.5) !important;
  padding: 1.5rem;
  z-index: 100;
  border-radius: 30px;
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.style_ScrollUpButton__Container__oH0hx:hover {
  background-color: #666666 !important;
}
.style_ScrollUpButton__Toggled__24k8M {
  right: 20px;
}
