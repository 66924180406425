.style_box__1ILHY {
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
}
.style_taskSucceeded__1Gmxs {
  background: rgba(72, 179, 80, 0.1);
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: #48B350;
}
.style_taskSucceeded__1Gmxs .style_title__UwJjE {
  color: #48B350;
}
.style_taskFailed__eD3aY {
  background: rgba(219, 68, 55, 0.1);
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: #db4437;
}
.style_taskFailed__eD3aY .style_title__UwJjE {
  color: #db4437;
}
.style_taskTime__1Y3GB {
  margin-right: 0.5rem;
}
