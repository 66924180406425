@media (max-width: 37.4rem) {
  .style_hide-sm___2kvH {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3Numw {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2OrU9 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2kD6o {
    display: none !important;
  }
}
.style_permissions__30d7L {
  list-style-type: disc;
  margin-left: 2rem;
}
.style_roleName__2cIb8 {
  margin-top: 0rem;
}
.style_user__1gmkZ {
  display: flex;
  align-items: center;
}
.style_userName__2Spfa {
  margin-left: 0.5rem;
}
.style_nameContainer__2Lvob {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.style_nameContainer__2Lvob .style_lockedCheck__1UGcR {
  padding-right: 0.5rem;
}
.style_permissionColumn__gx5nr {
  width: 30%;
}
@media (max-width: 37.4rem) {
  .style_permissionColumn__gx5nr {
    width: auto;
  }
}
.style_descriptionColumn__1BWel {
  width: auto;
}
