.style_container__1kDAN {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.style_container__1kDAN > .style_wide__1HOG7 {
  flex-grow: 1;
}
.style_container__1kDAN > .style_buttons__o7bCK {
  padding-top: 1rem;
}
.style_container__1kDAN > :nth-child(2) {
  margin-left: 1rem;
}
