@media (max-width: 37.4rem) {
  .style_hide-sm__2UQRh {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__z9KuZ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1Emaf {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2R1I5 {
    display: none !important;
  }
}
.style_topnavbar__1n9mQ {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.style_eapBanner__1TaWd {
  background-color: #fb8c00;
  color: #ffffff;
  font-size: 0.8125rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.style_eapBanner__1TaWd a,
.style_eapBanner__1TaWd a:hover {
  color: #ffffff;
}
.style_closeEAPBanner__WsR_D {
  position: absolute;
  right: 0;
  top: -0.75rem;
  height: auto;
}
.style_navbar__2hAqE {
  height: 3rem;
  background-color: #2f93e0;
  display: flex;
}
.style_navbarLeft__3y-qM,
.style_navbarRight__2cg1C {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}
.style_navbarCenter__3dC2h {
  flex-grow: 1;
  flex-shrink: 1;
}
.style_navbarCenter__3dC2h ul {
  height: 100%;
  margin: 0;
  display: flex;
}
.style_navbarCenter__3dC2h ul li {
  display: inline-block;
  height: 100%;
}
.style_navbarCenter__3dC2h ul li a {
  cursor: pointer;
  height: 100%;
  color: #ffffff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.style_navbarCenter__3dC2h ul li a:hover {
  background-color: #74c0fb;
  color: #ffffff;
}
.style_navbarCenter__3dC2h ul li a:active,
.style_navbarCenter__3dC2h ul li a:focus {
  background-color: #0A67B1;
}
.style_navbarCenter__3dC2h ul li a em {
  font-size: 1.0625rem;
  padding: 0 0.5rem;
}
.style_navbarRight__2cg1C {
  float: right;
}
.style_button__3OnKD {
  cursor: pointer;
  height: 100%;
  color: #ffffff;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.style_button__3OnKD:hover {
  background-color: #74c0fb;
  color: #ffffff;
}
.style_button__3OnKD:active,
.style_button__3OnKD:focus {
  background-color: #0A67B1;
}
.style_navItemActive__1U75X {
  background-color: #0A67B1;
}
.style_spaceIcon__2iW84 {
  margin-right: 1rem;
}
.style_icon__15rk7 {
  font-size: 1.0625rem;
  padding: 0 0.5rem;
}
.style_userName__3C_WL,
.style_spaceName__1fgP2 {
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
  padding-left: 0.5rem;
  min-width: 3rem;
}
.style_navbarRight__2cg1C > div {
  height: 100%;
}
@media (max-width: 37.4rem) {
  .style_navbar__2hAqE {
    height: 2.5rem;
  }
  .style_navbarRight__2cg1C {
    margin-right: 0.5rem;
  }
  .style_navbarLeft__3y-qM {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    float: none;
  }
}
