.style_filterField__14cTd {
  position: relative;
  display: inline-block;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
  /* clears the padding and overlay issues on Safari */
}
.style_filterField__14cTd .style_searchIcon__2jWe5 {
  position: absolute;
  right: 0;
  top: 10px;
  width: 1.5rem;
  height: 1.5rem;
}
.style_filterField__14cTd input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.style_filterField__14cTd input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.style_filterField__14cTd input[type="search"]::-webkit-search-decoration,
.style_filterField__14cTd input[type="search"]::-webkit-search-cancel-button,
.style_filterField__14cTd input[type="search"]::-webkit-search-results-button,
.style_filterField__14cTd input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.style_filterField__14cTd input[type="search"] {
  -webkit-appearance: none;
}
.style_filterTextBoxStandardWidth__3sgtd {
  width: 15.6rem !important;
  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}
.style_filterTextBoxFullWidth__vbgRS {
  width: 100% !important;
}
