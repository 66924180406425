.style_listItem__2R7EP {
  padding: 1.25rem;
  word-wrap: break-word;
  font-size: 0.875rem !important;
}
.style_listItem__2R7EP:hover {
  background-color: rgba(153, 153, 153, 0.08) !important;
}
.style_listItem__2R7EP > div:first-child {
  padding: 0!important;
}
.style_listItem__2R7EP > div:first-child > div:first-child {
  padding: 0.875rem 1rem!important;
}
.style_loadMore__2AC6e {
  text-align: center;
}
.style_pagingControlContainer__27Jnu {
  margin: 1.5rem 1rem;
}
.style_disablePagingEvents__3Jluj {
  pointer-events: none;
}
