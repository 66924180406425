@media (max-width: 37.4rem) {
  .style_hide-sm__2ig6F {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__KwLQP {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3LP0L {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__32qoS {
    display: none !important;
  }
}
.style_components__YCODK {
  margin-top: 0;
}
.style_components__YCODK li {
  margin-top: 0.5rem;
}
.style_entry__14zqW {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.style_entry__14zqW ul.style_bulletList__NAb2X {
  margin-left: 3rem;
}
.style_entry__14zqW ul.style_bulletList__NAb2X li {
  list-style: disc;
}
.style_entry__14zqW .style_content__gXRDf {
  color: #333333;
  font-size: 0.875rem;
}
.style_entry__14zqW .style_styleGuideExample__3VISp {
  margin-bottom: 0.5rem;
}
.style_container__1SDjW {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
}
.style_container__1SDjW .style_componentMenu__2bB58 {
  min-width: 18.75rem;
}
.style_container__1SDjW > ul {
  list-style: none;
}
.style_container__1SDjW > ul > li {
  margin-bottom: 5px;
}
@media (max-width: 37.4rem) {
  .style_container__1SDjW {
    flex-direction: column;
  }
}
.style_heading__1VKzd {
  text-align: left;
}
.style_content__gXRDf {
  margin: 2rem 1rem;
}
.style_context__2CU84 {
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(153, 153, 153, 0.08);
  font-size: 0.875rem;
  color: #666666;
}
.style_context__2CU84 p {
  font-size: 0.875rem;
  color: #666666;
}
thead {
  font-weight: 600;
}
.style_swatch__1jbu5 {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid #cccccc;
}
.style_white__2-m00 {
  background: #ffffff;
}
.style_primaryText__22NFE {
  background: #333333;
}
.style_secondaryText__3PSey {
  background: #9e9e9e;
}
.style_primary__1QtFq {
  background: #2f93e0;
}
.style_primaryLight__3W9rY {
  background: #74c0fb;
}
.style_primaryDark__1T-SF {
  background: #0A67B1;
}
.style_readonlyTextWithStrikethrough__1wQf9 > input {
  text-decoration: line-through;
  color: #9e9e9e !important;
}
.style_sidebarWithColor__361k5 {
  background-color: grey;
}
.style_row__rEsb3 {
  display: flex;
  flex-direction: row;
}
.style_buttons__1r9hu tr td {
  padding: 0.5rem 0;
}
.style_card__2cyda {
  padding-bottom: 0.5rem;
}
.style_cardTitle__bF6f_ {
  font-weight: 500;
  padding: 1rem;
}
