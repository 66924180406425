.style_notVisible__1igeg {
  opacity: 0;
}
.style_fadeIn__2odMF {
  opacity: 1;
  transition: opacity 300ms ease-in;
  -moz-transition: opacity 300ms ease-in;
  -webkit-transition: opacity 300ms ease-in;
}
.style_container__9IC8f {
  height: 100%;
}
