.style_icon__115Yt {
  align-items: center;
}
.style_icon__115Yt:hover {
  opacity: 1;
}
.style_editIcon__2QxG9 {
  align-items: center;
  opacity: 0.5;
  color: #9e9e9e;
}
.style_editIcon__2QxG9:hover {
  opacity: 1;
}
.style_deletedIcon__3xCWP {
  align-items: center;
  color: #db4437;
}
.style_deletedIcon__3xCWP:hover {
  opacity: 1;
}
.style_newIcon__3hHaI {
  align-items: center;
  color: #48B350;
}
.style_newIcon__3hHaI:hover {
  opacity: 1;
}
