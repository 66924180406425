.style_sortableItem__uXsLf {
  border: 0.0625rem solid #cccccc;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: rgba(153, 153, 153, 0.08);
  cursor: move;
  color: black;
}
.style_name__1lT_b {
  padding-left: 1rem;
}
