.style_internalLink__hWOg_ {
  color: #2f93e0;
  text-decoration: none;
}
.style_internalLink__hWOg_:hover {
  color: #0A67B1;
}
.style_listInternalLink__2JIcM {
  color: inherit;
}
.style_listInternalLink__2JIcM:hover,
.style_listInternalLink__2JIcM:focus {
  color: inherit;
}
