@media (max-width: 37.4rem) {
  .style_hide-sm__2DqnL {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2UT5x {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1d3KU {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3CE1p {
    display: none !important;
  }
}
.style_titleBar__2vtZO {
  background-color: #ffffff;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid #cccccc;
}
.style_rowSpaced__2MWMG {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.style_title__3BWuz {
  flex-grow: 1;
  font-size: 1.25rem;
}
.style_title__3BWuz h1,
.style_title__3BWuz h2 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}
.style_actions__bxiA0 {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
}
.style_helpAction__Hb9Vq {
  margin-left: 0.5rem;
}
.style_pageTitleLink__3VX6G {
  color: #333333;
  text-decoration: none;
}
.style_pageTitleLink__3VX6G > :active,
.style_pageTitleLink__3VX6G > :visited {
  color: #333333;
}
.style_pageTitleLink__3VX6G:hover {
  color: #2f93e0;
}
