@media (max-width: 37.4rem) {
  .style_hide-sm__15-XM {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__22qnf {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__6Qat- {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__hP3tp {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_specialActions__3LZ8n {
    padding-left: 1rem;
  }
}
