.style_breadcrumb__3uVeg {
  font-size: 0.875rem;
  /* mark.siedle: This matches the dataTableColumnHeader, so this has a consistent look. */
  line-height: 1rem;
  color: #9e9e9e;
}
.style_breadcrumb__3uVeg .style_breadcrumbLink__26UIc {
  color: #333333;
  text-decoration: none;
}
.style_breadcrumb__3uVeg .style_breadcrumbLink__26UIc > :active,
.style_breadcrumb__3uVeg .style_breadcrumbLink__26UIc > :visited {
  color: #333333;
}
.style_breadcrumb__3uVeg .style_breadcrumbLink__26UIc:hover {
  color: #2f93e0;
}
