@media (max-width: 37.4rem) {
  .style_hide-sm__18SrA {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3jfSO {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2bD4a {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2otVc {
    display: none !important;
  }
}
.style_machinePolicySummary__1B1px {
  color: #333333;
  font-size: 0.8125rem;
}
@media (max-width: 37.4rem) {
  .style_machinePolicySummary__1B1px {
    padding: 0;
    display: none;
  }
}
.style_machinePolicySummaryLabel__3IfHJ {
  text-align: left;
  font-weight: 600;
}
@media (max-width: 37.4rem) {
  .style_machinePolicySummaryLabel__3IfHJ {
    text-align: initial;
  }
}
.style_machinePolicySummary__1B1px table {
  background-color: transparent;
}
.style_machinePolicySummary__1B1px tr:hover {
  background-color: transparent !important;
}
.style_machinePolicySummary__1B1px tr {
  border: 0 !important;
}
.style_machinePolicySummary__1B1px td {
  padding: 0.125rem 0 !important;
}
