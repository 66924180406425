.style_checkboxMargin__3hg0p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.style_title__S3YN7 {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.style_checkboxNote__2w019 {
  margin-left: 2rem;
}
.style_message__emr-e {
  font-size: 0.75rem;
}
.style_message__emr-e hr {
  border-width: 2px;
  margin-bottom-width: 0.25rem;
}
