@media (max-width: 37.4rem) {
  .style_hide-sm__22c_N {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm___OFVi {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2qegh {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__c6Do0 {
    display: none !important;
  }
}
.style_container__2d-ZV {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.style_newTagContainer__3InGA {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}
.style_newTagContainer__3InGA > *:not(:first-child):not(:last-child) {
  margin: 0 0.5rem;
}
.style_newTagContainer__3InGA > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.style_newTagContainer__3InGA > div:nth-child(1) > *:nth-child(2) {
  margin: 0 0.5rem;
}
@media (max-width: 37.4rem) {
  .style_newTagContainer__3InGA > div:nth-child(1) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.style_newTagContainer__3InGA > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  width: 40%;
  align-items: center;
}
.style_newTagContainer__3InGA > div:nth-child(2) > *:nth-child(2) {
  margin: 0 0.5rem;
}
@media (max-width: 37.4rem) {
  .style_newTagContainer__3InGA > div:nth-child(2) {
    width: 100%;
  }
  .style_newTagContainer__3InGA > div:nth-child(2) > *:nth-child(1) {
    width: auto;
  }
}
@media (max-width: 37.4rem) {
  .style_newTagContainer__3InGA {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.style_existingTagcontainer__2BQ4W {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 0.0625rem solid #cccccc;
  padding: 0.5rem 0;
}
.style_existingTagcontainer__2BQ4W > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_existingTagcontainer__2BQ4W > div:nth-child(1) > *:nth-child(2) {
  margin: 0 0.5rem;
}
@media (max-width: 37.4rem) {
  .style_existingTagcontainer__2BQ4W > div:nth-child(1) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 37.4rem) {
  .style_existingTagcontainer__2BQ4W {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
}
.style_description__356si {
  color: #666666;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  max-width: 300px;
  font-weight: 300;
}
.style_actions__tKhv9 {
  margin-left: auto;
}
.style_deleted__16qTX {
  text-decoration: line-through;
}
.style_reorder__3Sl89 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}
