.style_group__XcoXi {
  margin-top: 4rem;
}
.style_deployAllCell__3bUT9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_deploy__2-pu3 {
  text-transform: uppercase;
  text-decoration: none;
  color: #2f93e0;
  font-size: 0.875rem;
}
.style_dashboardTable__u9qby td {
  border-bottom: dashed 0.0625rem #cccccc;
}
.style_border__3oCt4 {
  border-bottom: dashed 0.0625rem #cccccc;
}
.style_spacerCell__3cPHi {
  border-bottom: dashed 0.0625rem #cccccc;
  width: 100%;
}
.style_headerCell__1vkjb {
  font-size: 1.0625rem;
  padding: 1rem 1.125rem 1rem 1rem;
  font-weight: 500;
  word-break: break-all;
}
.style_headerCell__1vkjb a {
  font-weight: 500;
}
.style_columnHead__3xd2m {
  font-size: 1.0625rem;
  padding: 1rem 1.125rem 1rem 1rem;
  font-weight: 400;
  display: flex;
}
.style_highlightColumn__1NNIj {
  font-weight: 600;
  padding-left: 1rem;
  display: flex;
}
.style_centerCell__3pBQt {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  min-width: 12.5rem;
  max-width: 18.75rem;
  word-wrap: break-word;
  word-break: break-all;
}
.style_fullHeight__2--7I {
  height: 100%;
}
.style_blankCell__3R3OG {
  width: 0.0625rem;
  height: 5rem;
}
.style_container__3pi1q {
  overflow: hidden;
  margin-bottom: 1rem;
}
.style_scrollableContainer__2KvAn {
  flex-grow: 1;
  overflow-x: auto;
}
.style_emptyCell__2w9G_ {
  padding: 1rem;
  width: 100%;
}
.style_headersOverflowContainer__fOf2X {
  z-index: 2;
  position: absolute;
  background-color: #ffffff;
  overflow-x: auto;
}
.style_headersContainer__Hv477 {
  display: flex;
  flex-direction: row;
}
.style_dataContainer__3yPm_ {
  display: flex;
  flex-direction: row;
  position: relative;
}
.style_firstColumnContainer__329Et {
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.style_scrollbarPadding__1k0I5 {
  height: 20px;
}
.style_hideScrollbarPanel__1OauG {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #ffffff;
  width: 100%;
  height: 20px;
}
.style_rightContainer__xYD8U {
  overflow-x: hidden;
  flex-grow: 1;
}
.style_scrollableTableContainer__gXrhA {
  display: flex;
}
.style_innerGrid__1XQFw {
  box-sizing: content-box !important;
  overflow-x: scroll !important;
  overflow-y: scroll !important;
  padding-right: 1rem;
  padding-bottom: 1rem;
  -ms-overflow-style: none;
}
.style_innerGrid__1XQFw:focus {
  outline: none;
}
.style_innerGrid__1XQFw::-webkit-scrollbar {
  display: none;
}
.style_loadMoreContainer__EUZSE {
  padding: 0 1rem 1rem 1rem;
}
.style_loadMoreActions__965er {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.style_loadMoreSubText__1GaGw {
  font-size: 0.75rem;
}
