@media (max-width: 37.4rem) {
  .style_hide-sm__1iO4Y {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__325fR {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3S0Y6 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__34itG {
    display: none !important;
  }
}
.style_container__2ucVQ {
  width: 12.75rem;
  padding: 1rem 1rem 0 1rem;
  margin: 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 auto;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  overflow: hidden;
  color: #333333;
  text-decoration: none;
  background: #ffffff;
  transition: background-color 0.1s ease-in;
}
.style_container__2ucVQ:active,
.style_container__2ucVQ:visited {
  color: #333333;
  text-decoration: none;
}
.style_container__2ucVQ:hover {
  box-shadow: 0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.2), 0 0.1875rem 0.25rem 0 rgba(0, 0, 0, 0.12), 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.14);
}
.style_container__2ucVQ:hover .style_onHover__24AN0 {
  background-color: rgba(158, 158, 158, 0.4);
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.4rem) {
  .style_container__2ucVQ {
    margin: 0.8rem 0;
  }
}
.style_onHover__24AN0 {
  display: none;
}
.style_header__Wbyi7 {
  max-width: 12.25rem;
  font-size: 1.0625rem;
  margin-top: 1rem;
  text-align: center;
  color: #333333;
  font-weight: 400;
  word-wrap: break-word;
  word-break: break-word;
}
.style_link__36QVE > a {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.style_disabled__1kalA * {
  color: #9e9e9e;
}
.style_content__39WQq {
  text-align: center;
  max-width: 12.25rem;
  color: #666666;
  font-size: 0.8125rem;
  line-height: 1rem;
  max-height: 4.5rem;
  margin-bottom: 1rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: 300;
}
.style_content__39WQq p {
  margin: 0.5rem 0;
}
.style_contentFade__1uGkG:after {
  position: absolute;
  bottom: -1rem;
  height: 3rem;
  width: 100%;
  content: "";
  background: linear-gradient(to top, #ffffff 40%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  left: 0;
  z-index: 1;
  overflow: hidden;
}
.style_footer__3nWkt {
  margin-top: auto;
  width: 12.75rem;
  font-size: 0.625rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
@media (max-width: 37.4rem) {
  .style_footer__3nWkt {
    justify-content: center;
  }
}
.style_footerIcon__sHq6l {
  margin-left: 0.1875rem;
}
@media (max-width: 37.4rem) {
  .style_footerIcon__sHq6l {
    margin-left: 0;
  }
}
@media (max-width: 37.4rem) {
  .style_content__39WQq {
    display: none;
  }
  .style_header__Wbyi7 {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5rem;
    max-width: none;
    flex: 1 1 auto;
    text-align: left;
  }
  .style_container__2ucVQ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.5rem;
    height: auto;
    width: 100%;
  }
  .style_footer__3nWkt > * {
    display: none;
  }
  .style_footer__3nWkt {
    padding: 0;
    margin: 0;
    width: 1.5rem;
    height: auto;
    display: flex;
    align-items: center;
  }
  .style_footerIcon__sHq6l {
    display: block;
  }
}
