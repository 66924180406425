@media (max-width: 37.4rem) {
  .style_hide-sm__Q7vwA {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__35Qlg {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2PnUq {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1nXs5 {
    display: none !important;
  }
}
.style_bodyWithSides__JEfDX {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
}
@media (max-width: 37.4rem) {
  .style_bodyWithSides__JEfDX {
    flex-direction: column;
  }
}
@media (min-width: 37.4rem) {
  .style_sideBarRight__1Jk18 {
    border-left: 0.0625rem solid #cccccc;
  }
}
@media (min-width: 37.4rem) {
  .style_sideBarLeft__2nMAi {
    border-right: 0.0625rem solid #cccccc;
  }
}
.style_sidebarContainer__2rAHd {
  flex-basis: 17.6rem;
  padding-bottom: 1rem;
}
.style_sideBar__3-Hyv {
  font-size: 0.875rem;
}
@media (max-width: 37.4rem) {
  .style_sideBar__3-Hyv {
    border-top: 0.0625rem solid #cccccc;
  }
}
.style_sideBarPadding__wKuJl {
  padding: 0 1rem;
}
.style_main__1M33p {
  flex-grow: 1;
  flex-basis: 1rem;
  min-width: 0;
}
@media (max-width: 37.4rem) {
  .style_main__1M33p {
    flex-basis: auto;
  }
}
.style_overflowXHidden__2XH7O {
  overflow-x: hidden;
}
.style_mainContent__1TCTx {
  padding-top: 0;
  height: 100%;
}
