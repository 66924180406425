.style_description__2JnZh {
  margin-bottom: 0.25rem;
}
.style_empty__dMEC3 {
  font-size: 1rem;
  margin: 0;
  line-height: 1.5rem;
}
.style_grid__1rRkV {
  display: flex;
  flex-direction: column;
}
.style_grid__1rRkV .style_row__2guYn {
  display: flex;
  flex-direction: row;
  margin: 0.3rem 0;
}
.style_grid__1rRkV .style_row__2guYn .style_header__1zDPZ {
  flex-basis: 13%;
  text-align: left;
  margin-right: 1.25rem;
  font-weight: 600;
}
.style_designRuleButton__1bMIp {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.25rem;
}
.style_codeWrapper__R7IwQ {
  position: relative;
  height: 15rem;
}
.style_rulesList__3x3j_ {
  margin: 0;
  padding-left: 1rem;
  list-style-type: decimal;
}
.style_rulesList__3x3j_ li {
  padding-bottom: 0.625rem;
}
.style_rulesList__3x3j_ li:last-child {
  padding-bottom: 0;
}
.style_rulesEditHeader__1HiJa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.style_addVersionActionContainer__2zMpp {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
