.styles_clickableTag__2HdVo > div:first-child:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  border-radius: 1rem;
  z-index: 0;
}
.styles_clickableTag__2HdVo > div:first-child:hover:after {
  opacity: 1;
}
.styles_clickableTag__2HdVo > div span {
  z-index: 1;
}
