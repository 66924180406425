.style_headerColumn__34Q3I:nth-child(3) {
  padding-left: 2.95rem;
}
.style_rowColumn__k1PAt {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
  padding: 0.175rem 1.125rem!important;
  vertical-align: middle!important;
}
.style_addRule__86K0k {
  margin: 1.0rem 0rem;
}
