.style_controlWithRefreshContainer__vJGTD {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
}
.style_controlWithRefreshContainer__vJGTD > div:first-child {
  /* Necessary hack */
  width: 100%;
}
.style_refreshContainer__2HQ0m {
  align-self: flex-end;
}
