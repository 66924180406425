.style_typeName__klqBM {
  color: #333333;
  padding: 0;
  margin: 0 0 0.5rem 0;
}
.style_typeHeader__1Z8Pc {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_typeHeaderTitleContainer__13aPE {
  margin: 0 1rem;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.style_typeBody__S3LrV {
  visibility: visible;
}
.style_account__1bdiF p {
  margin: 0.5rem 0;
  color: #9e9e9e;
}
