.style_taskDetailsContainer__32ZqS {
  display: flex;
  flex-direction: row;
  min-width: 180px;
  padding: 0.5rem;
  margin: 0.25rem 0;
  justify-content: left;
}
.style_taskDetailsContainer__32ZqS .style_details__3KwZU {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
}
.style_taskDetailsContainer__32ZqS .style_description__1kEpb {
  color: #333333;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: left;
}
.style_taskDetailsContainer__32ZqS .style_date__10fjd {
  color: #666666;
  font-weight: 400;
  font-size: 0.6875rem;
  white-space: nowrap;
}
.style_stripTopBottomPadding__kBWr2 {
  padding: 0 0.5rem;
  margin: 0;
}
