@media (max-width: 37.4rem) {
  .style_hide-sm__1GTHH {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__EwMw_ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3J4DD {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1ZF4K {
    display: none !important;
  }
}
.style_headerLinks__6GPnb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 37.4rem) {
  .style_headerLinks__6GPnb {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_assignmentAction__3RgsS {
  margin-left: 0.5rem;
}
.style_notes__1xIMb {
  background-color: #ffffff;
  width: 100%;
}
.style_actions__283a4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.style_formElements__15Qw4 {
  background-color: #ffffff;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}
