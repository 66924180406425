.feedteststyle_headerColumn__Wne6a {
  max-width: none !important;
}
.feedteststyle_headerColumn__Wne6a:first-child {
  width: 18.75rem;
}
.feedteststyle_headerColumn__Wne6a:nth-child(2) {
  width: 9.375rem;
}
.feedteststyle_rowColumn__2xRJV {
  width: auto !important;
  max-width: none !important;
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
}
