.style_container__1o4lP {
  min-height: 62.5rem;
}
.style_filterSearchBoxContainer__JjpAT {
  width: 320px;
}
.style_sectionDivider__1DY3o {
  padding: 0.5rem 1.125rem;
  margin-right: 1rem;
  font-size: 1.0625rem;
  border-radius: 0.25rem;
}
