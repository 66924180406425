@media (max-width: 37.4rem) {
  .style_hide-sm__B2xDE {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1XzM8 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__35qg3 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2DFDX {
    display: none !important;
  }
}
.style_formContainer__2WZ93 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
}
.style_snackContainer__2shMw {
  text-align: center;
}
