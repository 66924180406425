.style_container__6zhjr {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.style_name__30M1A {
  min-width: 0;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}
.style_description___22nA {
  flex-shrink: 0;
  flex-grow: 0;
}
