.style_controlCell__vzhD2 {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.style_lastColumn__3MG7y {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.style_scopeCell__3qLpV {
  flex-grow: 1;
  flex-shrink: 1;
}
.style_fullSizeCell__3JG3f {
  height: 100%;
  width: 100%;
}
