.style_checkboxContainer__2vOGp {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_checkbox__na2KW {
  flex-grow: 1;
}
.style_checkboxIcon__1EoNW {
  flex-grow: 0;
}
