.style_certInstructionList__2givz {
  margin: 0;
  margin-left: 2rem;
  list-style-type: decimal;
}
.style_certInstructionList__2givz li {
  padding-bottom: 0.625rem;
}
.style_certInstructionList__2givz li:last-child {
  padding-bottom: 0;
}
.style_testingSuccess__1q6Du {
  display: flex;
}
.style_testingSuccess__1q6Du span:nth-child(2) {
  margin-left: 0.5rem;
}
