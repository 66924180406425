@media (max-width: 37.4rem) {
  .style_hide-sm__2MKOq {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2FxAY {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__P0eZR {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1x3GD {
    display: none !important;
  }
}
.style_heading__2C8Pw {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
}
.style_headingText__jkNpI {
  flex-grow: 1;
}
.style_resetButton__2zaiz {
  margin: 0 !important;
}
.style_fullHeightContainer__279cz {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  height: 100%;
}
.style_headingSection__gvjxo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.style_filterHeaderContainer__1o7fx {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
}
@media (max-width: 37.4rem) {
  .style_filterHeaderContainer__1o7fx {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_filterHeaderContainer__1o7fx > div:last-child {
  margin-left: 1rem;
}
@media (max-width: 37.4rem) {
  .style_filterHeaderContainer__1o7fx > div:last-child {
    margin-left: unset;
    margin-top: unset;
  }
  .style_filterHeaderContainer__1o7fx > div:last-child > button > div > svg {
    margin-left: unset !important;
  }
}
.style_additionalHeaderFilter__1wnFr {
  align-self: flex-end;
}
.style_filterHeaderContainer__1o7fx .style_additionalHeaderFilter__1wnFr:not(:first-child) {
  margin-left: 1rem;
}
.style_filteringByItems__qNbT4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.style_filteringBy__34BL1 {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.style_matchInfo__ZRNEa {
  margin-left: 0.75rem;
}
.style_info__2LOCc {
  color: #0878D1;
}
