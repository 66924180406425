.style_filterBox__2n7tp {
  margin-left: 1rem;
  margin-top: 2.6rem;
}
.style_filter__mdggZ {
  width: auto;
  align-items: flex-end;
}
.style_row__1UWYX {
  display: flex;
  flex-direction: row;
}
.style_avatar__2PBJL {
  vertical-align: middle;
  justify-content: center;
  padding-right: 0.5rem;
}
.style_linkWithCopy__3dWty {
  display: inline-flex;
  flex-direction: row;
}
.style_linkWithCopy__3dWty .style_copyAction__2ioVN {
  margin: -1rem 0;
}
.style_linkWithCopy__3dWty .style_link__AdAJg {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
