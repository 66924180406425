@media (max-width: 37.4rem) {
  .DeploymentPart_hide-sm__2ukn_ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .DeploymentPart_visible-sm__1eQ6l {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .DeploymentPart_hide-md__1o-I2 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .DeploymentPart_visible-md__1N62q {
    display: none !important;
  }
}
.DeploymentPart_stepList__9gw7U {
  display: flex;
  flex-direction: column;
}
.DeploymentPart_stepList__9gw7U > :last-child > .DeploymentPart_listItemLine__3qhhN,
.DeploymentPart_stepList__9gw7U > :last-child > .DeploymentPart_listItem__2CYzF .DeploymentPart_listItemLine__3qhhN {
  display: none;
}
.DeploymentPart_stepList__9gw7U > :last-child .DeploymentPart_stepContainer__2GFKG {
  border-bottom: unset;
}
.DeploymentPart_listItem__2CYzF {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 1rem;
  margin: 0;
  position: relative;
  overflow: visible;
  z-index: 0;
  height: auto;
  background-color: transparent;
  border-top: 0.0625rem solid transparent;
  border-bottom: 0.0625rem solid transparent;
  color: #333333;
  margin-top: -0.125rem;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_listItemLine__3qhhN {
  position: absolute;
  top: 0.5rem;
  left: 2.6rem;
  height: 100%;
  border-left: 0.0625rem solid #666666;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_parallelContainer__IiXu6 {
  padding: 0.25rem;
  border-radius: 0.625rem;
  position: absolute;
  top: -0.25rem;
  left: 2.1rem;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 1rem;
  height: 1rem;
  background: #cccccc;
  align-items: center;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_parallelIcon__3Wfkl {
  width: 0.75rem;
  height: 0.375rem;
  border-top: 0.125rem solid #333333;
  border-bottom: 0.125rem solid #333333;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_parallelIcon__3Wfkl .DeploymentPart_content__nIr2U {
  width: 100%;
  height: 100%;
  display: block;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepContainer__2GFKG {
  border-bottom: 0.0625rem solid #cccccc;
  border-top: 0.0625rem solid transparent;
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0 0.75rem 0;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepContent__q6rEv {
  display: flex;
  flex-direction: row;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_summary__14ptV {
  display: block;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  color: #333333;
  font-weight: 400;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_contextualActions__2_bE1 {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepLogo__33105 {
  width: 3.2rem;
  height: 3.2rem;
  z-index: 1;
  flex-shrink: 0;
  border: 0.0625rem solid #cccccc;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 0.0625rem;
  margin-top: 0.5rem;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepLogo__33105 svg {
  padding-left: 0.0625rem;
  width: auto;
  height: 60%;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepLogo__33105 img {
  width: 2.2rem;
  border-radius: 2.2rem;
  height: auto;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepName__22Y5l {
  width: 100%;
  color: #2f93e0;
  font-weight: 500;
  font-size: 1rem;
  flex-direction: column;
  justify-content: flex-start;
}
.DeploymentPart_listItem__2CYzF .DeploymentPart_stepNumber__xavhr {
  font-weight: 600;
  margin: 0 0.25rem 0 0;
  font-size: 1rem;
}
.DeploymentPart_listItem__2CYzF:hover {
  background-color: rgba(153, 153, 153, 0.08) !important;
  margin-top: -0.125rem;
}
.DeploymentPart_listItem__2CYzF:hover .DeploymentPart_stepContainer__2GFKG .DeploymentPart_stepContent__q6rEv .DeploymentPart_stepNumber__xavhr,
.DeploymentPart_listItem__2CYzF:hover .DeploymentPart_stepContainer__2GFKG .DeploymentPart_stepContent__q6rEv .DeploymentPart_stepName__22Y5l {
  color: #0A67B1;
}
.DeploymentPart_isDisabled__2u8Iz * {
  color: #9e9e9e !important;
}
.DeploymentPart_isDisabled__2u8Iz * .DeploymentPart_stepNumber__xavhr {
  padding-top: 0.25rem;
}
.DeploymentPart_group__gXBNO > .DeploymentPart_listItem__2CYzF {
  align-items: stretch;
}
.DeploymentPart_group__gXBNO > .DeploymentPart_listItem__2CYzF .DeploymentPart_stepName__22Y5l,
.DeploymentPart_group__gXBNO > .DeploymentPart_listItem__2CYzF .DeploymentPart_stepNumber__xavhr {
  font-size: 0.875rem;
}
.DeploymentPart_group__gXBNO > .DeploymentPart_listItem__2CYzF .DeploymentPart_stepLogo__33105 {
  border-radius: 0;
  margin: 0;
  height: 100%;
  border-left: 0.0625rem solid #cccccc;
  border-right: 0.0625rem solid #cccccc;
  border-top: 0.0625rem solid #cccccc;
  border-bottom: 0.0625rem solid transparent;
}
.DeploymentPart_group__gXBNO > .DeploymentPart_listItem__2CYzF .DeploymentPart_stepContainer__2GFKG {
  line-height: 1.25rem;
}
.DeploymentPart_group__gXBNO > a:first-child .DeploymentPart_stepName__22Y5l,
.DeploymentPart_group__gXBNO > a:first-child .DeploymentPart_stepNumber__xavhr {
  font-size: 1rem;
}
.DeploymentPart_group__gXBNO > a:first-child .DeploymentPart_stepLogo__33105 {
  border-bottom: 0 solid transparent;
  background-color: #0A67B1;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  justify-content: center;
  margin-top: 0.5rem;
}
.DeploymentPart_group__gXBNO > a:first-child .DeploymentPart_stepLogo__33105 svg {
  fill: #ffffff;
  width: auto;
  height: 100%;
  padding-left: 0;
}
.DeploymentPart_group__gXBNO > a:first-child:hover svg {
  fill: #333333;
}
.DeploymentPart_group__gXBNO > a:first-child:hover .DeploymentPart_stepLogo__33105 {
  border-top: 0.0625rem solid #cccccc;
}
.DeploymentPart_group__gXBNO > a:last-child .DeploymentPart_stepLogo__33105 {
  border-top: 0.0625rem solid #cccccc;
  border-bottom: 0.0625rem solid #cccccc;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  margin-bottom: 0.5rem;
  flex: 1;
}
.DeploymentPart_group__gXBNO > a:last-child:hover .DeploymentPart_stepLogo__33105 {
  border-bottom: 0.0625rem solid #cccccc;
}
.DeploymentPart_stepWrapper__2nT53 {
  display: flex;
  flex-direction: column;
}
.DeploymentPart_stepWrapper__2nT53 div:nth-child(2) {
  flex: 1;
}
