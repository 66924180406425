.style_checkboxGroup__3K_d3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.style_checkbox__8SFK- {
  position: relative;
  overflow: visible;
  display: table;
  height: auto;
  min-width: 6.875rem;
}
.style_horizontal__19xZ8 > div {
  width: auto!important;
  margin-right: 3rem;
}
.style_disabled__1kFAu * {
  color: #9e9e9e;
}
.style_disabled__1kFAu * svg {
  opacity: .5;
}
