.style_markdownEditorContainer__1LAkR {
  position: relative;
  width: 100%;
}
.style_markdownControlsContainer__1PWWn {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.style_restrictedHeight__1KFqe {
  max-height: 15rem;
}
.style_iconButton__1zmYm {
  width: 2rem !important;
  min-width: 2rem !important;
}
.style_iconButton__1zmYm:hover span {
  opacity: 1;
}
.style_iconButton__1zmYm span {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  opacity: 0.6;
}
.style_markdownSupported__1hw2R {
  padding-right: 0.25rem;
}
.style_iconButtonContainer__aRSR_ {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: rgba(153, 153, 153, 0.08);
  border: 0.0625rem solid #cccccc;
  border-bottom: 0;
}
.style_textarea__3aWKY {
  max-width: inherit !important;
}
.style_markdownControlsLink__CPtWr {
  font-size: 0.75rem;
  font-weight: 300;
}
