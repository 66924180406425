@media (max-width: 37.4rem) {
  .style_hide-sm__13WXD {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2mCwV {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__N8mjj {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3Nz9L {
    display: none !important;
  }
}
.style_container__ou1qo {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 37.4rem) {
  .style_container__ou1qo {
    flex-wrap: wrap;
  }
}
.style_input__2LqcU {
  flex: 1 1 auto;
}
.style_actions__2cI8l {
  margin-left: 0.5rem;
}
