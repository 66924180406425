@media (max-width: 37.4rem) {
  .SpacesMenu_hide-sm__yEokr {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .SpacesMenu_visible-sm__g-ciZ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .SpacesMenu_hide-md__1otve {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .SpacesMenu_visible-md__2I81b {
    display: none !important;
  }
}
.SpacesMenu_spaceIcon__3-M-T {
  margin-left: 1rem;
  background-color: #ffffff;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.1875rem;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #ffffff;
  overflow: hidden;
}
.SpacesMenu_spaceIcon__3-M-T img {
  max-width: 1.5rem;
  max-height: 1.5rem;
}
.SpacesMenu_spaceMenu__RPMuh {
  background-color: #0F2535;
  text-decoration: none;
  cursor: pointer;
}
.SpacesMenu_spaceMenu__RPMuh a {
  height: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SpacesMenu_spaceMenu__RPMuh a:hover {
  background-color: #74c0fb;
  color: #ffffff;
}
.SpacesMenu_spaceMenu__RPMuh a:active,
.SpacesMenu_spaceMenu__RPMuh a:focus {
  background-color: #0A67B1;
}
.SpacesMenu_caret__1x0ii {
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}
.SpacesMenu_title__CjgaN {
  margin-left: 0.5rem;
  white-space: nowrap;
  font-size: 1rem;
  max-width: 12.5rem;
  overflow: hidden;
  font-weight: 500;
}
@media (max-width: 37.4rem) {
  .SpacesMenu_title__CjgaN {
    display: none;
  }
}
