.style_container__3z7ZN {
  display: flex;
  width: 100%;
  align-items: flex-end;
}
.style_selectedIcon__1npwT {
  top: 0.2rem;
  position: relative;
  padding-right: 0.5rem;
}
.style_outerContainer__2VItF {
  width: 100%;
}
.style_innerContainer__26xHr {
  overflow: hidden;
  text-overflow: ellipsis;
}
.style_actions__KoiXY {
  margin-left: 0.5rem;
}
.style_select__1_qiK {
  flex: 1 1 auto;
}
