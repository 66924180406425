.style_status__1ZDlk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.125rem;
  font-size: 1.25rem;
  color: #ffffff;
}
.style_small__3FkU1 {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.875rem;
}
.style_Queued__DeyMU {
  background-color: #0878D1;
}
.style_Executing__3yznb {
  background-color: #0A67B1;
}
.style_Paused__lSLM3 {
  background-color: #fb8c00;
}
.style_TimedOut__VpLrB {
  background-color: #fb8c00;
}
.style_Failed__1oQpX {
  background-color: #db4437;
}
.style_Canceled__ExnAj {
  background-color: #333333;
}
.style_Cancelling__1oF2r {
  background-color: #333333;
}
.style_Success__LkzoC {
  background-color: #48B350;
}
.style_SuccessWithWarnings__o1p7G {
  background-color: #48B350;
}
