.style_cellWrapper__L-u12 {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
}
.style_cellContentWrapper__3txba {
  flex-grow: 1;
  min-width: 0;
  flex-basis: 0;
}
.style_alignedCellsWrapper__1BwYA {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
