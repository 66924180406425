@media (max-width: 37.4rem) {
  .style_hide-sm__3do7O {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1EytG {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1LhEh {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__27qI7 {
    display: none !important;
  }
}
.style_textControl__9CUmz {
  flex: 1 1 auto;
}
.style_textControlKey__2aPuL {
  max-width: 12.5rem;
}
.style_separator__1VC1t {
  margin: 0 2rem;
  font-weight: 600;
  align-self: center;
}
.style_itemContainer__2m-bA {
  display: flex;
  align-items: flex-start;
}
.style_threeItemContainer__1D4Xi {
  flex-direction: column;
}
@media (max-width: 79.9rem) {
  .style_textControl__9CUmz {
    width: 100%;
  }
  .style_itemContainer__2m-bA {
    flex-direction: column;
  }
  .style_separator__1VC1t {
    display: none;
  }
}
