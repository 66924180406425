@media (max-width: 37.4rem) {
  .AccountSummary_hide-sm__1Te6g {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .AccountSummary_visible-sm__2zGkC {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .AccountSummary_hide-md__1nB6H {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .AccountSummary_visible-md__2UFlx {
    display: none !important;
  }
}
.AccountSummary_container__3Ap6b {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.AccountSummary_select__AORJ6 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
.AccountSummary_summary__tX8Nr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 100%;
}
.AccountSummary_row__2qBlu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.AccountSummary_row__2qBlu:nth-last-child(1) {
  margin-top: 0.5rem;
}
.AccountSummary_titleContainer__1UYoT {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.AccountSummary_title__3Qs3n {
  width: 100%;
}
.AccountSummary_details__12wTC {
  display: flex;
  flex-direction: row;
}
