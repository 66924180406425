.style_lifecycle-map__1Rb5E {
  position: relative;
  overflow: hidden;
}
.style_lifecycle-map__1Rb5E > ul:before {
  content: "";
  position: absolute;
  top: 1.25rem;
  bottom: 1.5rem;
  left: 0.375rem;
  width: 0.0625rem;
  background: #9e9e9e;
}
.style_lifecycle-map__1Rb5E li {
  position: relative;
  list-style-type: none;
  margin: 0.5rem 0.25rem 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.36rem;
  text-align: left;
}
.style_lifecycle-map__1Rb5E > ul > li.style_phase__3ghxk .style_phase-name__ZenTa {
  font-weight: 500;
}
.style_lifecycle-map__1Rb5E li.style_phase__3ghxk ul {
  list-style-type: none;
  margin-left: 1.5rem;
  padding-left: 0;
}
.style_lifecycle-map__1Rb5E li.style_phase__3ghxk li {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.style_lifecycle-map__1Rb5E li.style_target__1MrYR:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: -1.125rem;
  width: 1.125rem;
  height: 0.0625rem;
  background: #9e9e9e;
}
.style_lifecycle-map__1Rb5E li.style_phase__3ghxk {
  padding-left: 0;
}
