@media (max-width: 37.4rem) {
  .style_hide-sm__2U-bb {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3bPur {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1Mc8b {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2QQFz {
    display: none !important;
  }
}
.style_summaryText__300qD {
  text-align: left;
  font-weight: 400;
  margin: 0.25rem 0;
  font-size: 0.875rem;
}
.style_summarySection__QK_sT {
  margin-top: 0.5rem;
  color: #333333;
  font-size: 0.875rem;
}
.style_summarySectionKey__2Ai6g {
  text-align: left;
  font-weight: 600;
  margin: 0.75rem 0 0.25rem 0;
}
.style_summarySectionValue__1J7Rp {
  text-align: left;
  font-weight: 400;
  margin: 0.25rem 0;
}
.style_summaryTable__3QiuS {
  margin-top: 0.5rem;
  padding: 0 1rem;
  color: #333333;
  font-size: 0.875rem;
}
.style_summaryTableKey__2soSk {
  text-align: left;
  font-weight: 600;
  width: 25%;
}
@media (max-width: 37.4rem) {
  .style_summaryTableKey__2soSk {
    width: unset;
    text-align: initial;
  }
}
.style_summaryTableValue__2d6kx {
  text-align: left;
  font-weight: 400;
}
@media (max-width: 37.4rem) {
  .style_summaryTableValue__2d6kx {
    text-align: initial;
  }
}
.style_summaryTable__3QiuS table {
  background-color: transparent;
}
.style_summaryTable__3QiuS tr:hover {
  background-color: transparent !important;
}
.style_summaryTable__3QiuS tr {
  border: 0 !important;
}
.style_summaryTable__3QiuS td {
  padding: 0.125rem 0 !important;
}
