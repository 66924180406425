.style_packageTableContainer__1IMWG {
  overflow-x: auto;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS {
  font-size: 1rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS th {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_packageTableRowColumn__hvzb0 {
  vertical-align: middle !important;
  padding: 0.25rem 1.125rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_packageTableRowColumnName__2cENK {
  min-width: 10rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_packageColumn__13uE4 {
  width: 25%;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_latestColumn__1CVjt {
  width: 23%;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_lastColumn__1cXMZ {
  width: 23%;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_specificColumn__iov3u {
  width: 29%;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_versionTextbox__1SMuA {
  width: 18rem !important;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_specificVersionDiv__2Jrx_ {
  display: flex;
  align-items: center;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_inlineDiv__1_tx8 {
  display: inline-block;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_inlineDiv__1_tx8 .style_editVersionArea__ROqbZ {
  min-width: 6.875rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_radioButtonContainer__y72YX {
  margin-top: -0.6rem;
}
.style_packageTableContainer__1IMWG .style_packageTable__1d1qS .style_radioButtonContainer__y72YX .style_myRadioButton__9kzCD {
  margin-right: 0.5rem;
}
.style_actionName__3ZOnL {
  font-size: 0.875rem;
  color: #9e9e9e;
}
