@media (max-width: 37.4rem) {
  .style_hide-sm__EsJVt {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__wFHpz {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3QALo {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3m8DW {
    display: none !important;
  }
}
.style_inputRow__SszxY {
  flex: 3;
  padding-left: 1rem;
  padding-right: 2rem;
}
.style_detailsHighlight__1_Qc7 {
  padding-top: 1rem;
  background-color: rgba(153, 153, 153, 0.08);
}
.style_rightAlignedContainer__1m78V {
  display: flex;
  flex-direction: row-reverse;
}
.style_textFormatSelector__3C68D {
  margin-bottom: -32px;
  width: 10rem;
}
.style_content__2EwIg {
  display: flex;
  height: 100%;
}
@media (max-width: 79.9rem) {
  .style_content__2EwIg {
    display: block;
  }
}
.style_value__28_7_ {
  flex: 7 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.style_value__28_7_ > * {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.style_editor__3Ngkc {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.style_editorContainer__3JRU5 {
  flex: 1 1 auto;
}
