* {
  box-sizing: border-box;
}
.executingStyle_outer__1IfQj {
  display: inline-block;
  background: #0878D1;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0.0625rem;
  vertical-align: top;
  margin-right: 0.5rem;
  margin-top: 0.0625rem;
}
.executingStyle_border__1u9jC {
  background: #0878D1;
  width: 0.75rem;
  height: 0.75rem;
  border: solid 0.0625rem #ffffff;
  border-radius: 50%;
  padding: 0.0625rem;
}
.executingStyle_wrapper__xT-Ds {
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  background: #0878D1;
  border-radius: 50%;
}
.executingStyle_pie__3pVM2 {
  width: 50%;
  height: 100%;
  transform-origin: 100% 50%;
  position: absolute;
  background: #ffffff;
}
.executingStyle_spinner__1G7zP {
  border-radius: 0.25rem 0 0 0.25rem;
  z-index: 200;
  border-right: none;
  animation: executingStyle_rota__1NCH7 5s linear infinite;
}
.executingStyle_filler1__4_0m_ {
  border-radius: 0 0.25rem 0.25rem 0;
  left: 50%;
  opacity: 0;
  z-index: 100;
  animation: executingStyle_fill1__n19jj 5s steps(1, end) infinite;
  border-left: none;
}
.executingStyle_filler2__10XQE {
  border-radius: 0.25rem 0 0 0.25rem;
  opacity: 1;
  z-index: 100;
  animation: executingStyle_fill2__AOXCi 5s steps(1, end) infinite;
  border-left: none;
}
.executingStyle_mask1__3s3js {
  border-radius: 0.25rem 0 0 0.25rem;
  width: 50%;
  height: 100%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 300;
  animation: executingStyle_mask1__3s3js 5s steps(1, end) infinite;
}
.executingStyle_mask2__2WxWW {
  border-radius: 0 0.25rem 0.25rem 0;
  width: 50%;
  height: 100%;
  left: 50%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 300;
  animation: executingStyle_mask2__2WxWW 5s steps(1, end) infinite;
}
@keyframes executingStyle_rota__1NCH7 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  50.01% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@keyframes executingStyle_mask1__3s3js {
  0% {
    opacity: 1;
  }
  25%,
  100% {
    opacity: 0;
  }
}
@keyframes executingStyle_mask2__2WxWW {
  0% {
    opacity: 0;
  }
  75%,
  100% {
    opacity: 1;
  }
}
@keyframes executingStyle_fill1__n19jj {
  0% {
    opacity: 0;
  }
  25%,
  50% {
    opacity: 1;
  }
  50.01%,
  100% {
    opacity: 0;
  }
}
@keyframes executingStyle_fill2__AOXCi {
  0% {
    opacity: 0;
  }
  50%,
  75% {
    opacity: 1;
  }
  75.01%,
  100% {
    opacity: 0;
  }
}
