.ImageWithPlaceholder_imageContainer__59IPy {
  position: relative;
  width: 100%;
}
.ImageWithPlaceholder_imageContainer__59IPy .ImageWithPlaceholder_image__2sTsD {
  position: relative;
  width: 100%;
}
.ImageWithPlaceholder_imageContainer__59IPy .ImageWithPlaceholder_loadingPlaceholder__1k8Pl {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
