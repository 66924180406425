@media (max-width: 37.4rem) {
  .style_hide-sm__2AniY {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1LSTL {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__Qe6s9 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2JoAJ {
    display: none !important;
  }
}
.style_root__1aJfw {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}
