.styles_codeEditorContainer__kcdLr {
  position: relative;
  min-height: 15rem;
  border: 0.0625rem solid #cccccc;
}
.styles_codeEditorContainerFullScreen__2FmpK {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 999;
  background: #fff;
}
.styles_enterFullScreen__3wGci {
  position: relative;
  text-align: right;
  float: right;
  padding-right: 1.25rem;
  z-index: 9;
}
.styles_exitFullScreen__19wf7 {
  text-align: right;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.5rem;
}
 {
  /* these need to not have their names mangled, as codemirror expects specific names */
}
.CodeMirror-foldmarker {
  color: #333333;
  text-shadow: #9e9e9e 1px 1px 2px, #9e9e9e -1px -1px 2px, #9e9e9e 1px -1px 2px, #9e9e9e -1px 1px 2px;
  font-family: arial;
  line-height: .3;
  cursor: pointer;
}
.CodeMirror-foldgutter {
  width: .7em;
}
.CodeMirror-foldgutter-open, .CodeMirror-foldgutter-folded {
  cursor: pointer;
}
.CodeMirror-foldgutter-open:after {
  content: "\25BE";
}
.CodeMirror-foldgutter-folded:after {
  content: "\25B8";
}
