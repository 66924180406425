.style_textContainer__2MwTF {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.style_iconContainer__1TzMq {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 0.5625rem;
  top: 18px;
}
.style_autoCompleteContainer__3Oqg2 {
  width: 100%;
}
.style_popover__37nKN > div {
  overflow-x: hidden;
}
.style_popoverContent__OcmAl {
  overflow-x: hidden;
}
.style_popoverInfoText__3rNC6 {
  margin: 1rem;
}
.style_warning__2Dm-w {
  margin: 0.5rem 1rem;
  color: #fb8c00;
  font-size: 0.75rem;
}
.style_buttons__wiJpT {
  position: absolute;
  top: 1.3rem;
  right: 0;
}
