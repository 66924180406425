.style_controlCell__3qdLY {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.style_lastColumn__3mwCT {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.style_scopeCell__3oBbG {
  flex-grow: 1;
  flex-shrink: 1;
}
.style_fullSizeCell__1SimM {
  height: 100%;
  width: 100%;
}
