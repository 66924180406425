.style_taskDetailsCell__3-XvW {
  display: flex;
}
.style_blockIcon__3VWIF {
  opacity: 0.2;
  font-size: 2.5rem;
}
.style_cell__1Xast {
  padding: 0.5rem;
}
