.style_container__wRKZk {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.style_select__1BBf8 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.style_inlineSearch__3yOWG {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.style_claimContent__mSRjf {
  display: block;
}
