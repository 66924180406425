.style_pageBanners__2omlB {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.style_warning__1hoFr {
  width: 100%;
  text-align: center;
  white-space: pre-line;
}
