@media (max-width: 37.4rem) {
  .EndpointCard_hide-sm__3Yi-l {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .EndpointCard_visible-sm__X3xMt {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .EndpointCard_hide-md__3X01T {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .EndpointCard_visible-md__1uDSV {
    display: none !important;
  }
}
.EndpointCard_card__1wHb2 {
  flex: 1;
  min-width: 16.25rem;
  max-width: 18.75rem;
}
@media (max-width: 37.4rem) {
  .EndpointCard_card__1wHb2 {
    max-width: 100%;
  }
}
.EndpointCard_card__1wHb2 .EndpointCard_description__UEVG_ {
  margin-top: 0.5rem;
}
.EndpointCard_card__1wHb2 div:nth-child(1) {
  width: 100%;
  text-align: center;
}
@media (max-width: 37.4rem) {
  .EndpointCard_card__1wHb2 div:nth-child(1) {
    width: auto;
  }
}
.EndpointCard_card__1wHb2 div:nth-child(1) .EndpointCard_logo__2pt_6 {
  width: auto;
  height: 5rem;
  margin: 0 auto;
}
.EndpointCard_card__1wHb2 div:nth-child(1) .EndpointCard_logo__2pt_6 img {
  height: 5rem;
}
.EndpointCard_card__1wHb2 div:nth-child(1) div {
  width: auto;
}
.EndpointCard_onHoverActions__1g4ew {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
