@media (max-width: 37.4rem) {
  .style_hide-sm__32CWS {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__10gEH {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3fowN {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__30Wxz {
    display: none !important;
  }
}
.style_paperContent__1w-Qh {
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.12), 0 0 0.125rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  background-color: #ffffff;
  width: 100%;
  max-width: 1280px;
  margin: 0 0 1rem 0;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.style_paperContentFlat__3HlvB {
  box-shadow: 0;
  background-color: transparent;
  width: 100%;
  max-width: 1280px;
  margin: 0;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
@media (max-width: 37.4rem) {
  .style_paperContentFlat__3HlvB {
    margin: 0.8rem;
  }
}
.style_paperFullWidth__d3tp0 {
  max-width: none;
}
.style_container__3mtsL {
  display: flex;
  width: 100%;
  justify-content: center;
}
.style_headerContainer__316AR {
  background: #ffffff;
  border-radius: 0.25rem;
  overflow: hidden;
}
.style_busyFromPromise__3IS0X {
  width: 100%;
}
.style_title__3dtC6 {
  word-wrap: break-word;
  background-color: #ffffff;
}
.style_stickied__mFxEA {
  box-shadow: 0 0.0625rem 0.1875rem -0.0625rem rgba(0, 0, 0, 0.34);
}
