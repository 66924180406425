@media (max-width: 37.4rem) {
  .style_hide-sm__j3PKf {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1p62b {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__WSrNu {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1xmlb {
    display: none !important;
  }
}
.style_machineIcon__T4TR4 {
  width: 2.438rem;
  height: 2.563rem;
}
.style_machineRow__27GRD {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-left: 1.5rem;
}
@media (max-width: 37.4rem) {
  .style_machineRow__27GRD {
    margin-left: 0.75rem;
  }
}
.style_machineRow__27GRD .style_machineImage__1594d {
  padding-right: 0.5rem;
}
.style_machineRow__27GRD .style_machineNameContainer__1aJhy {
  width: 13.44rem;
}
.style_machineRow__27GRD .style_machineNameContainer__1aJhy .style_machineName__gpZ7h {
  font-weight: 500;
  color: #333333;
  padding-right: 0.5rem;
}
.style_machineRow__27GRD .style_machineNameContainer__1aJhy .style_machineSummary__1eCtQ {
  font-size: 0.6875rem;
  color: #9e9e9e;
  padding-right: 1rem;
}
.style_machineRow__27GRD .style_chipContainer__3HoKR {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
