.style_headerColumn__jwTpL:first-child {
  width: 18.75rem;
}
.style_headerColumn__jwTpL:nth-child(3) {
  width: 1rem;
}
.style_rowColumn__3bg6H {
  word-break: break-all;
  word-wrap: break-word;
  white-space: normal;
  padding: 0.175rem 1.125rem!important;
  vertical-align: middle!important;
}
.style_feedName__3S__P {
  margin: 1rem 0;
}
