@media (max-width: 37.4rem) {
  .style_hide-sm__3F0Yk {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__24hsH {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1m65B {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__34mMi {
    display: none !important;
  }
}
.style_componentRow__vs8c9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (max-width: 37.4rem) {
  .style_componentRow__vs8c9 {
    flex-direction: column;
  }
}
.style_componentRow__vs8c9 > * {
  margin-right: 1rem;
}
@media (max-width: 37.4rem) {
  .style_componentRow__vs8c9 > * {
    margin-right: unset;
  }
}
