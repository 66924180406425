.style_environmentList__1Us-9 {
  padding: 0!important;
}
.style_phaseDeleteButton__2pWNc {
  margin-right: 1.4rem!important;
  margin-top: 0.5rem!important;
}
.style_sectionControl__cxJro {
  margin-top: 1rem;
}
.style_actionsMenu__19-G1 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.style_anyEnvironment__BxPa_ {
  margin-top: 1rem;
  display: block;
}
.style_padUnder__1fiBN {
  margin-bottom: 0.25rem;
}
.style_missingResource__1Qji_ {
  color: #db4437;
}
.style_strongText__13g8p {
  font-weight: strong;
}
