.style_timePicker__3QHl5 {
  display: flex;
  align-items: center;
}
.style_timePicker__3QHl5 .style_digit__2psjf {
  width: 2rem;
  margin-right: 0.25rem;
}
.style_timePicker__3QHl5 .style_digit__2psjf:first-of-type {
  margin-right: 1rem;
}
.style_timePicker__3QHl5 input[type=number]::-webkit-inner-spin-button,
.style_timePicker__3QHl5 input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.style_timePicker__3QHl5 input[type=number] {
  -moz-appearance: textfield;
}
.style_timePicker__3QHl5 input {
  text-align: center;
}
