@media (max-width: 37.4rem) {
  .style_hide-sm__1VVAP {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__qRpDG {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1u5td {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__u8SkJ {
    display: none !important;
  }
}
.style_empty__47H9L {
  font-size: 1rem;
  margin: 0 1rem;
  line-height: 1.5rem;
}
.style_paperLayoutOverride__WhWzq > div:first-child {
  box-shadow: unset;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 0px;
  border-radius: unset;
}
.style_cardTitleContainer__2_hE3 {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0 1rem;
  cursor: pointer;
}
.style_cardTitleContainer__2_hE3 .style_workerPoolIcon__3tyBY {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
  width: 1.5rem;
  height: auto;
}
.style_cardTitleContainer__2_hE3 .style_workerPoolName__27h3t {
  color: #2f93e0;
  font-weight: 500;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  white-space: nowrap;
  font-size: 1rem;
}
.style_cardTitleContainer__2_hE3 .style_workerPoolMachinesCount__2HieC {
  color: #9e9e9e;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_cardTitleContainer__2_hE3 .style_workerPoolSummaryCounts__184rO {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 5rem;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainer__2_hE3 .style_workerPoolSummaryCounts__184rO {
    display: none;
  }
}
.style_cardTitleContainer__2_hE3 .style_workerPoolSummaryCounts__184rO .style_summaryCount__242ic {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  display: flex;
  align-items: center;
  line-height: 1rem !important;
  font-size: 0.8125rem !important;
  text-transform: uppercase;
}
.style_cardTitleContainer__2_hE3 .style_workerPoolOverflowActions__3e0hr {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  right: 3rem;
  margin: auto;
  width: 3rem;
  height: 2.25rem;
}
.style_machineIcon__1gwyp {
  width: 2.438rem;
  height: 2.563rem;
}
.style_healthStatusIcon__25DWv {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: middle;
}
.style_healthStatusCardTitleContainer__ttWvo {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0;
  margin-left: 1.5rem;
}
@media (max-width: 37.4rem) {
  .style_healthStatusCardTitleContainer__ttWvo {
    margin-left: 0.75rem;
  }
}
.style_healthStatusCardTitleContainer__ttWvo .style_healthStatusIconContainer__8dzD1 {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__ttWvo .style_healthStatusName__BZNQ- {
  color: #333333;
  font-weight: 500;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__ttWvo .style_healthStatusMachinesCount__3p2BE {
  color: #9e9e9e;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_expandLink__3M2uQ {
  width: 6rem;
  height: 1.19rem;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.36;
  color: #9e9e9e;
  margin-right: 1.5rem;
}
.style_linkContainer__kmnYZ {
  width: 100%;
  text-align: right;
}
.style_hide__1BYA0 {
  font-family: Roboto;
  font-size: 0.75rem;
  line-height: 1.83;
  text-align: left;
  color: #9e9e9e;
  text-align: right;
}
.style_hide__1BYA0 a {
  color: #2f93e0;
}
.style_workerPoolDescription__3vpWI {
  font-size: 0.875rem;
}
.style_workerPoolSelectorContainer__1j3Zk {
  width: 15.6rem !important;
  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}
.style_dynamicWorkerIcon__2i0Nh {
  background-color: #333333;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -4px;
  padding: 4px;
}
