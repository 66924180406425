.style_firstColumn__39Ua7 {
  width: 50%;
  margin-right: 1rem;
}
.style_firstColumnFullWidth__1y_qQ {
  width: 100%;
}
.style_secondColumn__2TV9I {
  width: 50%;
  margin-left: 1rem;
}
.style_scopeContainer__3YPCn {
  display: flex;
  flex-direction: row;
}
