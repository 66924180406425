.style_container__JFoHo {
  display: flex;
  width: 7rem;
  position: relative;
  flex: 0 0 7rem;
}
.style_colorSwatch__1TaV7 {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25em;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  cursor: pointer;
}
.style_colorPickItem__1guVO {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transition: background-color 0.1s linear;
  border-radius: 0;
}
.style_colorAllOptions__fQzjR {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
