@media (max-width: 37.4rem) {
  .EndpointSelector_hide-sm__2O-LI {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .EndpointSelector_visible-sm__3pM6Y {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .EndpointSelector_hide-md__1dlLR {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .EndpointSelector_visible-md__l4wYs {
    display: none !important;
  }
}
.EndpointSelector_centered__2UgTM {
  display: flex;
  justify-content: center;
}
.EndpointSelector_container__1m-PX {
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.EndpointSelector_active__EAjXH {
  background-color: #2f93e0 !important;
}
.EndpointSelector_cardGroup__Mxpms {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 100px;
}
.EndpointSelector_actions__1tkt4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
.EndpointSelector_headingContainer__3AtdI {
  background-color: #ffffff;
  margin: 0;
}
.EndpointSelector_paperActions__1HI9r {
  background-color: #ffffff;
  padding: 1rem;
}
.EndpointSelector_actions__1tkt4 > * {
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.EndpointSelector_link__1GHuv {
  cursor: pointer;
}
.EndpointSelector_groupHelp__3mXjS {
  margin: 0 1rem;
}
