.style_minimumEnvText__36NJQ {
  max-width: none!important;
  width: 2.5rem!important;
  display: inline-block !important;
  z-index: 10;
  height: 1.25rem!important;
  margin-bottom: 0.2rem!important;
}
.style_minimumEnvText__36NJQ hr {
  bottom: 0!important;
}
.style_minimumEnvText__36NJQ input {
  font-size: 0.875rem!important;
}
.style_minCompleteLabel__38K5K {
  margin-left: 0 !important;
  height: 1.25rem;
}
