.style_scopeRow__3lSQo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 1.25rem;
}
.style_scopeRow__3lSQo h4 {
  color: #666666;
}
.style_scopeRow__3lSQo > * {
  max-width: 15.625rem;
  min-width: 9.375rem;
  flex: 1 1 9.375rem;
}
.style_showAll__3mAz5 {
  margin-top: 0.5rem;
}
.style_summary__9ShhO {
  padding-left: 1rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  color: #666666;
}
.style_filterTextBox__1mYX6 {
  padding-left: 1rem;
}
