.style_icon__37qpC {
  align-items: center;
}
.style_icon__37qpC:hover {
  opacity: 1;
}
.style_controlCell__NtTd4 {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.style_lastColumn__3rd8c {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.style_scopeCell__1bvwF {
  flex-grow: 1;
  flex-shrink: 1;
}
.style_editIcon__2o8gw {
  align-items: center;
  opacity: 0.5;
  color: #9e9e9e;
}
.style_editIcon__2o8gw:hover {
  opacity: 1;
}
.style_newIcon__1qxD5 {
  align-items: center;
  color: #48B350;
}
.style_newIcon__1qxD5:hover {
  opacity: 1;
}
.style_variablePlaceholderText__36-Sx {
  height: 100%;
  align-items: flex-end;
  display: flex;
  color: #9e9e9e;
  font-size: 0.75rem;
  padding: 0.25rem 0;
}
