@media (max-width: 37.4rem) {
  .style_hide-sm__k_i5f {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1pBMM {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__8nGVX {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__KqPzb {
    display: none !important;
  }
}
h1 {
  font-size: 1.625rem;
  font-weight: 400;
}
@media (max-width: 37.4rem) {
  h1 {
    font-size: 1.4375rem;
  }
}
h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media (max-width: 37.4rem) {
  h2 {
    font-size: 1.0625rem;
  }
}
h3 {
  font-size: 1.125rem;
  font-weight: 400;
}
@media (max-width: 37.4rem) {
  h3 {
    font-size: 1.0625rem;
  }
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
}
h5 {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem 0;
}
hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: solid 0.0625rem #cccccc;
  margin: 1em 0;
  padding: 0;
}
a {
  color: #2f93e0;
}
a:hover {
  color: #0A67B1;
}
p {
  margin: 0.75rem 0;
  color: #333333;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.style_secondary__2-7A2 {
  color: #9e9e9e;
}
.style_section__Yw6Kx {
  flex-grow: 1;
}
.style_sectionHeader__3Txlc {
  padding: 0 1rem;
  margin: 0.5rem 0;
}
.style_sectionBody__dGjTq {
  padding: 0 1rem;
  color: #666666;
  font-size: 0.875rem;
}
.style_sectionBody__dGjTq p {
  color: #333333;
}
.style_sectionNoHeader__19EHX {
  margin: 0.5rem 0;
  width: 100%;
}
