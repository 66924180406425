@media (max-width: 37.4rem) {
  .style_hide-sm__4Cyu2 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1y0CN {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3oTDB {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2bceO {
    display: none !important;
  }
}
.style_cardListContainer__15ial {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.6rem 0;
}
.style_cardList__3fX6i {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}
.style_cardList__3fX6i > a {
  display: flex;
}
@media (max-width: 37.4rem) {
  .style_cardList__3fX6i > a {
    flex-direction: column;
  }
}
.style_loadMoreHeaderInfo__J2mWy {
  color: #0878D1;
  margin-left: 0.8rem;
}
@media (max-width: 37.4rem) {
  .style_loadMoreHeaderInfo__J2mWy {
    margin-left: 0;
  }
}
