@media (max-width: 37.4rem) {
  .style_hide-sm__2V77F {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2KM3G {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__GwjwS {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2DYnX {
    display: none !important;
  }
}
.style_errorText__GsoAj {
  margin-top: 0.3rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #db4437;
}
.style_isDefault__28Xib::after {
  content: " (default)";
  color: #9e9e9e !important;
}
.style_radioButton__2AVT0 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
}
.style_radioButtonGroup__2vJhi {
  flex-wrap: wrap;
}
.style_radioButtonGroup__2vJhi > :not(.style_radioButton__2AVT0) {
  margin-left: 2rem;
}
.style_label__3Sa9g {
  color: #9e9e9e;
  font-size: 0.8125rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.style_container__3VSgU {
  width: 100%;
}
.style_topMargin__1ldMf {
  margin-top: 1rem;
}
.style_horizontal__3mozs {
  display: flex;
  flex-direction: row;
}
@media (max-width: 37.4rem) {
  .style_horizontal__3mozs {
    flex-direction: column;
  }
}
.style_horizontal__3mozs > div {
  width: auto!important;
  margin-right: 3rem;
}
@media (max-width: 37.4rem) {
  .style_horizontal__3mozs > div {
    margin-right: unset;
  }
}
