.style_materialUIIcon__1sk0x {
  width: 1.5rem !important;
  height: 1.5rem !important;
  min-width: 1.5rem !important;
  line-height: 1.5rem !important;
  padding: 0 !important;
  opacity: 0.7;
}
.style_materialUIIcon__1sk0x span {
  padding: 0rem !important;
}
.style_materialUIIcon__1sk0x:hover {
  background-color: #ffffff !important;
}
.style_materialUIIcon__1sk0x:hover {
  opacity: 1;
}
.style_imageIcon__264Ki {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.7;
}
.style_imageIcon__264Ki:hover {
  opacity: 1;
}
.style_fontAwesomeIcon__wQsIJ {
  opacity: 0.7;
}
.style_fontAwesomeIcon__wQsIJ:hover {
  opacity: 1;
}
