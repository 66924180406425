.style_menuIcon__2JWLl {
  font-size: 1.37500rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333333;
}
.style_menuIcon__2JWLl:visited {
  color: #333333;
}
.style_menuIcon__2JWLl:hover {
  opacity: 0.5;
}
.style_container__dejaF {
  display: flex;
  flex-direction: column;
  width: 31.25rem;
}
.style_menuContainer__2j70S {
  overflow-y: auto;
  height: 25rem;
}
.style_filter__obcih {
  margin: 0 1rem;
}
