@media (max-width: 37.4rem) {
  .style_hide-sm__2aNBr {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__WW6AZ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__HTfTo {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1JWbW {
    display: none !important;
  }
}
.style_row__3SH1i {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.style_propertyContainer__1d0pp {
  padding: 0 0.4rem 0 0;
  display: flex;
  align-items: center;
}
.style_propertyContainer__1d0pp span {
  display: flex;
  align-items: center;
}
.style_propertyContainer__1d0pp span:nth-child(2) {
  margin-left: 0.25rem;
  margin-right: 0.75rem;
}
