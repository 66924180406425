.style_headerContainer__1V9ge {
  width: 100%;
}
.style_headerRowContainer__2Qs53 {
  display: flex;
  flex-direction: row;
}
.style_virtualList__2Xx2X {
  overflow: visible !important;
}
.style_virtualList__2Xx2X:focus {
  outline: none;
}
.style_virtualList__2Xx2X > * {
  overflow: visible !important;
}
.style_table__rj6PT {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.style_tableBody__3f7Ux {
  flex: 1 0 auto;
  will-change: transform;
}
