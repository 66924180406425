.style_headerColumn___FXe_:first-child {
  width: 18.75rem !important;
}
.style_rowColumn__5d7L7 {
  word-break: normal;
  white-space: normal;
  padding: 0rem 1.125rem!important;
  vertical-align: middle!important;
}
.style_rowColumn__5d7L7 p {
  margin: 0.5rem 0;
}
.style_center__2sYMN {
  text-align: center;
}
