@media (max-width: 37.4rem) {
  .style_hide-sm__2q_mk {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1a3Nn {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__5nmGm {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1oqXK {
    display: none !important;
  }
}
.style_permissions__yW3mK ul {
  list-style-type: disc;
  margin-left: 2rem;
  color: #666666;
  margin-top: 8px;
}
.style_permissions__yW3mK h5 {
  color: #333333;
  margin-bottom: 0;
}
.style_roleName__1WI51 {
  margin-top: 0rem;
}
.style_missingRoleName__2WBaz {
  margin-top: 0rem;
  color: #db4437;
}
.style_filterText__2LR9K {
  display: flex;
  align-items: center;
  margin: 0 0.125rem;
}
.style_lists__3psBs {
  display: flex;
}
@media (max-width: 37.4rem) {
  .style_lists__3psBs {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
.style_list__2unSb {
  flex: 50%;
  margin-top: 1rem;
}
.style_listHeader__1-bUf {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}
.style_listHeader__1-bUf h4 {
  margin: 0.5rem 0;
}
.style_row__2Lcsh {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
.style_detailsHighlight__1Xdh7 {
  padding: 1rem 0;
  background-color: rgba(153, 153, 153, 0.08);
}
.style_permissionDescriptionsActionContainer__3pU-U {
  margin: 0.25rem 0;
}
