@media (max-width: 37.4rem) {
  .style_hide-sm__SpCj6 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1mVju {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1hau- {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3rHNn {
    display: none !important;
  }
}
.style_deploymentsTableRow__1qecQ {
  font-weight: 500;
}
.style_deploymentsTableRow__1qecQ a {
  font-weight: 400;
}
.style_deploymentsTableRow__1qecQ td:last-child {
  padding-bottom: 0.5rem;
}
.style_phaseHeader__3v7jr {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 500;
}
.style_phaseHeader__3v7jr small {
  font-size: 0.75rem;
  margin-left: 0.1875rem;
  color: #9e9e9e;
  font-weight: 500;
}
.style_phaseEnvironmentRow__3B_MN td {
  padding-left: 2rem !important;
}
.style_deploymentRowButton__7ooTH {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}
.style_deploymentExpander__2B6tz {
  margin-left: 0.625rem;
  width: 3rem;
}
.style_deploymentDate__1w_Bh {
  font-weight: 400;
}
.style_deploymentDuration___ynQL {
  font-weight: 400;
  font-size: smaller;
}
.style_visible__2wlbc {
  display: table-row;
}
.style_hidden__1Wpst {
  display: none;
}
.style_taskDetailsContainer__2fDet {
  display: flex;
  flex-direction: row;
  min-width: 180px;
  justify-content: left;
}
.style_taskDetailsContainer__2fDet .style_details__3uut2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
}
.style_taskDetailsContainer__2fDet .style_description__244nM {
  color: #333333;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: left;
}
.style_taskDetailsContainer__2fDet .style_date__2Hys2 {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 0.6875rem;
}
@media (max-width: 37.4rem) {
  .style_channelLifecycleDetails__1kpGw {
    padding: 0 1rem 0.34rem;
  }
}
.style_expandCollapse__HasWr {
  display: flex;
  justify-content: flex-end;
}
