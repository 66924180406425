@media (max-width: 37.4rem) {
  .FundamentalsGuideDialogLayout_hide-sm__2Mxjs {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .FundamentalsGuideDialogLayout_visible-sm__1o0Tt {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .FundamentalsGuideDialogLayout_hide-md__2fU-x {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .FundamentalsGuideDialogLayout_visible-md__SyVaj {
    display: none !important;
  }
}
.FundamentalsGuideDialogLayout_centre__RNqcw {
  text-align: center;
}
.FundamentalsGuideDialogLayout_content__1FdzA {
  font-size: 0.875rem;
  color: #666666;
  margin: 1rem 9rem .5rem 7rem;
  min-height: 3.75rem;
}
.FundamentalsGuideDialogLayout_heading__k07_8 {
  font-size: 1.5rem;
  color: #333333;
  font-weight: 400;
  margin-top: 1rem;
}
.FundamentalsGuideDialogLayout_image__16_vq {
  width: auto;
  height: 26.5rem;
}
@media (max-width: 79.9rem) {
  .FundamentalsGuideDialogLayout_image__16_vq {
    width: 100%;
    height: auto;
  }
}
.FundamentalsGuideDialogLayout_container__3lwRU {
  position: relative;
}
.FundamentalsGuideDialogLayout_closeButton__1MIje {
  position: absolute;
  top: 0;
  right: 0;
}
