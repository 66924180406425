@media (max-width: 37.4rem) {
  .style_hide-sm__3SuPW {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2j7xQ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2cu5d {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__33ZqZ {
    display: none !important;
  }
}
.style_formExpander__1ybyg,
.style_formNonExpander__1klvw {
  background: rgba(153, 153, 153, 0.08) !important;
  width: 100%;
  box-shadow: none!important;
  margin: 0 0 -0.0625rem 0;
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
}
.style_formExpander__1ybyg > div:first-child,
.style_formNonExpander__1klvw > div:first-child {
  padding: 0!important;
}
.style_formExpander__1ybyg > div:first-child > div:first-child {
  cursor: pointer;
}
.style_cardMedia__W1jSW {
  margin: 0;
  padding: 0;
}
.style_cardTitleContainer__1N1aO {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(153, 153, 153, 0.08) !important;
  padding: 0.25rem 1rem !important;
  line-height: 2.25rem;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainer__1N1aO {
    display: block;
  }
}
.style_overflowMenuActions__2015B {
  margin-left: auto;
  align-items: center;
}
