@media (max-width: 37.4rem) {
  .InstalledActionTemplateList_hide-sm__1B0uY {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .InstalledActionTemplateList_visible-sm__1XJzx {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .InstalledActionTemplateList_hide-md__MFL6H {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .InstalledActionTemplateList_visible-md__3iYFM {
    display: none !important;
  }
}
.InstalledActionTemplateList_container__E770b {
  margin-top: 1rem;
}
.InstalledActionTemplateList_actionContainer__16k8C {
  display: flex;
  justify-content: flex-end;
}
.InstalledActionTemplateList_sectionDivider__3RWT1 {
  font-size: 1.0625rem;
}
.InstalledActionTemplateList_categoryTemplates__3p9ev > div,
.InstalledActionTemplateList_categories__1vVsA > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.InstalledActionTemplateList_categoryTemplates__3p9ev > div > *,
.InstalledActionTemplateList_categories__1vVsA > div > * {
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.InstalledActionTemplateList_categoryTemplates__3p9ev li {
  display: flex;
  height: 100%;
}
@media (max-width: 37.4rem) {
  .InstalledActionTemplateList_templates__2pNHX,
  .InstalledActionTemplateList_categoryTemplates__3p9ev > div {
    flex-direction: column;
  }
}
