.style_descriptionIcon__1vVMr {
  opacity: 0.5;
  color: #9e9e9e;
}
.style_highlightedDescriptionIcon__8iphr {
  opacity: 0.5;
  color: #9e9e9e;
}
.style_highlightedDescriptionIcon__8iphr:hover {
  opacity: 1;
}
.style_iconSizing__2CNyG {
  height: 1.5rem;
  width: 1.5rem;
}
.style_canClick__16MFP {
  cursor: pointer;
}
.style_iconContainer__mOh0l {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
