.style_formExpander__11ivf,
.style_formNonExpander__ezunm {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-width: 0!important;
  margin: 0 0 -0.0625rem 0;
  background-color: inherit !important;
}
.style_formExpander__11ivf > div:first-child,
.style_formNonExpander__ezunm > div:first-child {
  padding: 0!important;
}
.style_formExpander__11ivf > div:first-child > div:first-child {
  cursor: pointer;
  width: 100%;
}
.style_cardMedia__3nvsF {
  margin: 0;
  padding: 0;
}
